<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive max-width="800px" class="mx-auto">
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">
                Набор <br />
                "Анти Эйдж"
              </h2>
              <div class="img-top-box">
                <img
                  src="/img/made-in-germany.png"
                  alt="Made in Germany"
                  class="germany-top"
                />
                <div class="text-center">
                  <img
                    src="/img/antiage/anti-age-start.jpg"
                    alt="Набор Анти Эйдж"
                    class="anti-age-start"
                  />
                </div>
              </div>
              <div class="p-main">
                <h3 class="h3-green">Твой личный инструмент против старения</h3>
                <p class="mt-3">
                  Сегодня наука всё больше подтверждает: старение — не
                  неизбежность, а процесс, на который можно влиять. Некоторые
                  учёные даже называют его болезнью, с которой можно и нужно
                  работать. Полностью остановить старение пока невозможно,
                  <strong>
                    но с продуктами LR мы можем его замедлить и даже обратить
                    вспять!
                  </strong>
                </p>
                <p class="mt-3">
                  <strong>Антиэйдж-набор</strong> — это
                  <strong>ключевые продукты</strong> для поддержания молодости,
                  энергии и высокого качества жизни.
                </p>
                <p class="mt-3">Они помогают:</p>
                <v-row class="mt-2">
                  <v-col cols="2" class="text-center">
                    <img
                      src="/img/super_brain/brain-head-omega.png"
                      alt=""
                      class="honey-avatar"
                    />
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <strong>Заряжать организм энергией</strong> – активный
                      мозг, ясное мышление, высокий уровень жизненных сил.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="2" class="text-center">
                    <img
                      src="/img/metabolism/anti-oxidant.png"
                      alt=""
                      class="honey-avatar"
                    />
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <strong>Сохранять баланс (гомеостаз)</strong> – когда
                      системы организма работают гармонично, он быстрее
                      восстанавливается и адаптируется к нагрузкам.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="2" class="text-center">
                    <img
                      src="/img/super_brain/emotion-fon-icon.png"
                      alt=""
                      class="honey-avatar"
                    />
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <strong>Замедлять процессы старения</strong> – если
                      создать клеткам оптимальные условия, они начнут
                      омолаживаться, независимо от возраста.
                    </p>
                  </v-col>
                </v-row>
                <p class="mt-3">
                  Сегодня
                  <strong>
                    антиэйдж – не просто тренд, а осознанный подход
                  </strong>
                  к жизни. Начни прямо сейчас – поддержи своё здоровье и
                  активность на долгие годы!
                </p>
                <div
                  class="wistia_responsive_padding my-7"
                  style="padding: 56.25% 0 0 0; position: relative"
                >
                  <div
                    class="wistia_responsive_wrapper"
                    style="
                      height: 100%;
                      left: 0;
                      position: absolute;
                      top: 0;
                      width: 100%;
                    "
                  >
                    <iframe src="https://fast.wistia.net/embed/iframe/l6b4oj8g7d?web_component=true&seo=true" title="Набор АНТИЭЙДЖ . В.А.Дадали Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Синергия – каждый элемент набора дополняет и усиливает
                    другой
                  </h2>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="gel-avatar">
                        <img src="/img/antiage/gel-avatar-peach.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Гель Алоэ Вера – основа здоровья
                        </h3>
                        <p class="p-col">
                          Запускает процессы очищения и восстановления, улучшает
                          усвоение питательных веществ, поддерживает баланс
                          микрофлоры. Витамин C действует как мощный
                          антиоксидант, защищая клетки от старения.
                        </p>
                      </div>
                    </v-col>
                    <div class="synergy-box-mobile">
                      <img src="/img/metabolism/synergy.png" alt="" />
                    </div>
                    <v-col cols="12" sm="6">
                      <div class="omega-avatar">
                        <img src="/img/super_brain/omega-nerv.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Супер Омега – питание для мозга и тела
                        </h3>
                        <p class="p-col">
                          Обеспечивает клетки ценными жирными кислотами, которые
                          необходимы для работы мозга, нервной системы, кожи и
                          внутренних органов. Снижает воспаления, улучшает
                          кровообращение и эластичность сосудов.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="synergy-box">
                    <img src="/img/metabolism/synergy.png" alt="" />
                  </div>
                  <div class="synergy-box-mobile">
                    <img src="/img/metabolism/synergy.png" alt="" />
                  </div>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="pro-balance-avatar">
                        <img
                          src="/img/metabolism/pro-balance-avatar.png"
                          alt=""
                        />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Про Баланс – гармония внутренних процессов
                        </h3>
                        <p class="p-col">
                          Восстанавливает кислотно-щелочной баланс, что помогает
                          нормализовать обмен веществ, улучшить пищеварение и
                          укрепить кости. Поддерживает нервную систему, снижает
                          уровень стресса и усталости.
                        </p>
                      </div>
                    </v-col>
                    <div class="synergy-box-mobile">
                      <img src="/img/metabolism/synergy.png" alt="" />
                    </div>
                    <v-col cols="12" sm="6">
                      <div class="girl-mind">
                        <img src="/img/super_brain/girl-mind.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Майнд Мастер – энергия и защита от стресса
                        </h3>
                        <p class="p-col">
                          Борется с окислительным стрессом, поддерживает
                          концентрацию, память и нервную систему. Помогает
                          сохранять ясность ума, спокойствие и высокую
                          продуктивность в течение дня.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <strong>Комплексный эффект:</strong> благодаря
                    <strong>активатору усвоения</strong> – гелю Алоэ Вера – все
                    компоненты набора работают
                    <strong>максимально эффективно</strong>, помогая организму
                    оставаться в ресурсе, балансе и энергии.
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Гель Алоэ Вера Персик – активатор здоровья и молодости
                  </h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/aloe-leaf-iasc-mini.png"
                        alt=""
                        class="aloe-leaf-iasc"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <!-- <h3 class="h3-green">
                        ОСНОВНЫЕ СОСТАВЛЯЮЩИЕ ВАШЕГО ХОРОШЕГО САМОЧУВСТВИЯ
                      </h3> -->
                      <p class="mt-3">
                        <span class="title-orange">+ ПИТАНИЕ:</span>
                        <strong>
                          восполняет дефицит важных веществ и улучшает их
                          усвоение
                        </strong>
                        <br />
                        Гель Алоэ Вера
                        <strong>
                          сам богат более чем 200 биологически активными
                          соединениями:
                        </strong>
                        витаминами, минералами, аминокислотами и полисахаридами, ферментами.
                        Кроме того, научно доказано, что гель алое повышает биодоступность всех питательных веществ и, в частности, витаминов C, E,
                        более чем на 200%, помогая организму усваивать максимум
                        пользы из пищи и БАДов.
                      </p>
                      <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                      <ul class="mt-3 pl-5">
                        <li>
                          <strong>Естественное восполнение дефицитов</strong> –
                          клетки получают всё, что нужно для работы.
                        </li>
                        <li>
                          <strong>Усиленный эффект от витаминов и БАДов</strong>
                          – всё усваивается по максимуму.
                        </li>
                        <li>
                          <strong>Больше энергии и жизненных сил</strong> –
                          правильное питание даёт реальный результат.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <span class="title-orange">+ ЗАЩИТА:</span>
                    <strong>
                      Адаптоген, помогает приспособиться к трудным условиям и повысить устойчивость организма как к внешним, так и к внутренним агрессивным факторам. 
                    </strong>
                    <br />
                    Мощный антиоксидант, который борется со старением и болезнями.
                    Гель Алоэ Вера обладает
                    <strong>высокой антиоксидантной активностью</strong>,
                    защищает клетки от <strong>окислительного стресса</strong> –
                    одной из главных причин старения и хронических заболеваний.
                    <strong>Способствует образованию коллагена</strong>,
                    необходимого для
                    <strong>
                      здоровых суставов, крепких костей и упругой кожи и хорошего зрения</strong
                    >.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Замедление процессов старения</strong> – кожа,
                      волосы и тело дольше остаются молодыми
                    </li>
                    <li>
                      <strong>Сильный иммунитет и быстрая регенерация</strong> –
                      повышение сопротивляемости организма к инфекциям.
                    </li>
                    <li>
                      <strong>Крепкие суставы и гибкость</strong> – поддержка
                      хрящей и связок.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange">+ ЖКТ И ПИЩЕВАРЕНИЕ:</span>
                    <strong> комфорт, защита и восстановление</strong>
                    <br />
                    Гель Алоэ Вера способствует
                    <strong>заживлению слизистой ЖКТ</strong>, уменьшает
                    воспаления и защищает кишечник от повреждений.
                    <strong>Тормозит рост патогенной микрофлоры</strong>,
                    предотвращая образование биоплёнок, которые мешают
                    пищеварению и провоцируют заболевания.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong
                        >Здоровый кишечник и нормальное пищеварение</strong
                      >
                      – без вздутия, тяжести и дискомфорта.
                    </li>
                    <li>
                      <strong
                        >Защита от язв, гастритов и воспалений ЖКТ - устранение отклонений  в работе кишечника, избавление от запоров и синдрома раздраженного кишечника.</strong
                      >
                    </li>
                    <li>
                      <strong>Быстрое заживление  слизистых и нормализация функций всех отделов ЖКТ</strong>  – стабильно и надолго.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange">+ ПРОБИОТИЧЕСКИЙ ЭФФЕКТ:</span>
                    <strong> поддержка микрофлоры</strong>
                    <br />
                    Гель Алоэ Вера обладает
                    <strong>пребиотическими свойствами</strong> – он питает
                    полезные бактерии кишечника и способствует их росту. А
                    дополнительно входящий в состав
                    <strong>инулин</strong> (пребиотик) создаёт
                    <strong>идеальные условия для здоровой микрофлоры.</strong>
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Крепкий иммунитет</strong> – 80% защитных функций
                      организма зависит от кишечника.
                    </li>
                    <li>
                      <strong>Лучшее усвоение питательных веществ</strong> –
                      здоровая микрофлора улучшает обмен веществ.
                    </li>
                    <li>
                      <strong>Стабильный стул и лёгкость в животе.</strong>
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange"
                      >+ АНТИДИАБЕТИЧЕСКИЙ ЭФФЕКТ:</span
                    >
                    <strong> контроль уровня сахара в крови</strong>
                    <br />
                    Научные исследования подтверждают, что
                    <strong>Алоэ Вера снижает уровень глюкозы в крови</strong>,
                    уменьшает окислительный стресс и
                    <strong
                      >снижает негативное воздействие избытка инсулина</strong
                    >. Благодаря этому продукт подходит
                    <strong
                      >даже людям с диабетом и инсулинорезистентностью</strong
                    >.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Стабильный уровень сахара крови</strong> – меньше
                      скачков энергии и чувства усталости.
                    </li>
                    <li>
                      <strong
                        >Снижение рисков диабета и метаболических
                        нарушений.</strong
                      >
                    </li>
                    <li>
                      <strong
                        >Лучший контроль аппетита и меньше тяги к
                        сладкому.</strong
                      >
                    </li>
                  </ul>
                  <v-row class="mt-7">
                    <v-col cols="2" class="text-center">
                      <img
                        src="/img/products/av-peach.png"
                        alt=""
                        class="av-peach"
                      />
                    </v-col>
                    <v-col cols="10">
                      <h4 class="h4-peach">Чистый состав без сахара</h4>
                      <!-- <h4 class="h4-green">
                        Для поддержки ЖКТ, без добавления сахара
                      </h4> -->
                      <p class="mt-3">98% листового геля Алоэ Вера</p>
                      <p class="mt-3">
                        Инулин – природный пребиотик для микрофлоры
                      </p>
                      <p class="mt-3">Витамин C – 100% суточной потребности</p>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <strong
                      >Без сахара – подходит тем, кто следит за фигурой и даже
                      диабетикам.</strong
                    >
                  </p>
                  <p class="mt-3">
                    <strong
                      >Гель Алоэ Вера – это ежедневная поддержка здоровья,
                      молодости и энергии из самой природы!</strong
                    >
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/hq91dc2kz5?web_component=true&seo=true" title="Гель АВ со вкусом Персика Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Майнд Мастер – защита от стресса и поддержка мозга
                  </h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/super_brain/mind-master-pic.png"
                        alt=""
                        class="mind-master-pic"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p class="mb-5">
                        <strong
                          >Майнд Мастер – это антистрессовый комплекс</strong
                        >, который помогает сохранить
                        <strong
                          >ясность ума, концентрацию и высокую
                          работоспособность</strong
                        >
                        даже при интенсивных умственных нагрузках. Он насыщен
                        <strong>ключевыми витаминами и минералами,</strong>
                        которые поддерживают
                        <strong
                          >энергию, защиту нервных клеток и когнитивные
                          функции мозга.</strong
                        >
                      </p>
                      <h3 class="h3-green">
                        ВИТАМИНЫ ГРУППЫ В – энергия и ясное мышление
                      </h3>
                      <p class="mt-3">
                        Витамины группы B – это
                        <strong>фундамент здорового мозга.</strong> Они
                        участвуют в синтезе и транспорте нейромедиаторов,
                        формировании нервных волокон и  новых крепких нейронных связей, а также в работе множества
                        ферментативных реакций.
                      </p>
                      <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                      <ul class="mt-3 pl-5">
                        <li>
                          <strong>Больше энергии и концентрации</strong> – мозг
                          работает быстрее, лучше запоминает информацию.
                        </li>
                        <li>
                          <strong>Снижение усталости и стресса</strong> –
                          нервная система стабилизируется, меньше
                          раздражительности.
                        </li>
                        <li>
                          <strong>Защита мозга от старения</strong> –
                          замедляется гибель нейронов, что снижает риск
                          возрастных проблем с памятью.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <h3 class="h3-green mt-5">ВИТАМИН Е – мощная защита мозга</h3>
                  <p class="mt-3">
                    Витамин Е – один из самых
                    <strong>сильных антиоксидантов</strong>, который
                    <strong>защищает клетки мозга от повреждений.</strong> Он
                    предотвращает окисление полиненасыщенных жирных кислот, из
                    которых состоят мембраны всех клеток.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Ясность ума и высокая работоспособность</strong> –
                      повышение выносливости мозговой деятельности, ясность мыслей, отсутствие "тумана" в голове.
                    </li>
                    <li>
                      <strong>Защита от преждевременного старения мозга</strong> –
                      профилактика возрастных мозговых дисфункций и сохранение когнитивных функций головного мозга на долгие годы.
                    </li>
                  </ul>
                  <h3 class="h3-green mt-5">
                    ВИТАМИН C – поддержка памяти и интеллекта
                  </h3>
                  <p class="mt-3">
                    Витамин C играет
                    <strong>ключевую роль в работе мозга</strong>, так как его
                    концентрация в нейронах
                    <strong>в 200 раз выше, чем в плазме крови.</strong>
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Улучшение памяти и скорости мышления</strong> –
                      помогает запоминать информацию быстрее и думать чётче.
                    </li>
                    <li>
                      <strong>Поддержка когнитивных функций</strong> –
                      профилактика возрастных ухудшений работы мозга.
                    </li>
                  </ul>
                  <h3 class="h3-green mt-5">
                    Железо – профилактика усталости и потери концентрации
                  </h3>
                  <p class="mt-3">
                    Железо – один из важнейших элементов для мозга. Его дефицит
                    негативно влияет на способность к обучению, память и
                    когнитивные функции.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Бодрость и устойчивость к стрессу</strong> –
                      меньше усталости, больше мотивации.
                    </li>
                    <li>
                      <strong>Концентрация и продуктивность</strong> – мозг
                      работает эффективно в течение всего дня.
                    </li>
                  </ul>
                  <h3 class="h3-green mt-5">
                    Селен – защита мозга на долгие годы
                  </h3>
                  <p class="mt-3">
                    Селен необходим для
                    <strong>поддержания нормальной работы мозга</strong> и
                    предотвращения нейродегенеративных заболеваний, таких как
                    <strong>болезнь Альцгеймера и Паркинсона.</strong>
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong
                        >Светлый и ясный ум на долгие годы, профилактика снижения памяти.</strong
                      >
                    </li>
                    <li>
                      <strong
                        >Стабильность нервной системы и снижение
                        тревожности</strong
                      >
                    </li>
                    <li>
                      <strong
                        >Профилактика депрессии</strong
                      >
                    </li>
                  </ul>
                  <h3 class="mt-3 text-center">Почему именно Майнд Мастер?</h3>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong
                        >Комплексная поддержка мозга и нервной системы</strong
                      >
                    </li>
                    <li>
                      <strong>Защита от стресса и усталости</strong>
                    </li>
                    <li>
                      <strong
                        >Энергия, концентрация и высокая продуктивность</strong
                      >
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong
                      >Будь в ресурсе каждый день – твой мозг скажет тебе
                      «спасибо»!</strong
                    >
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/de5zpbqp6r?web_component=true&seo=true" title="Майнд Мастер Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Про Баланс – твой внутренний регулятор здоровья
                  </h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/pro-balance.png"
                        alt=""
                        class="img-pro-balance"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p>
                        <strong>Про Баланс</strong> – это комплекс минералов,
                        который
                        <strong>восстанавливает кислотно-щелочной баланс</strong
                        >, поддерживает обмен веществ и улучшает работу всех
                        систем организма. Он помогает
                        <strong
                          >оптимизировать условия для работы ферментов</strong
                        >, отвечающих за метаболизм, энергию и детоксикацию.
                      </p>
                      <p class="mt-3">
                        <span class="title-orange"
                          >+ ВОССТАНОВЛЕНИЕ ЭНЕРГИИ:</span
                        >
                        <strong> электролитный баланс без усталости</strong>
                        <br />
                        Минералы в составе
                        <strong>нормализуют электролитный баланс</strong>, влияя
                        на работу мышц, нервной системы и уровень энергии.
                      </p>
                      <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                      <ul class="mt-3 pl-5">
                        <li>
                          <strong>Больше сил и выносливости</strong> – усталость
                          уходит, тело работает эффективнее.
                        </li>
                        <li>
                          <strong>Лёгкость в движениях</strong> – мышцы и
                          суставы получают нужные микроэлементы.
                        </li>
                        <li>
                          <strong>Снижение риска обезвоживания</strong> –
                          поддержка водно-солевого обмена.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <span class="title-orange"
                      >+ ОПТИМАЛЬНАЯ СРЕДА ДЛЯ МЕТАБОЛИЗМА:</span
                    >
                    <br />
                    Все обменные процессы в организме зависят от работы
                    ферментов, но они эффективно функционируют
                    <strong
                      >только в сбалансированной кислотно-щелочной
                      среде.</strong
                    >
                    Любое отклонение pH замедляет реакции и может привести к
                    нарушениям.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Ускорение обмена веществ</strong> – тело быстрее
                      перерабатывает пищу в энергию.
                    </li>
                    <li>
                      <strong>Лучшее усвоение белков, жиров и углеводов</strong>
                      – организм использует питательные вещества по максимуму.
                    </li>
                    <li>
                      <strong>Поддержку контроля веса</strong> – профилактика
                      метаболических нарушений.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange">+ ДЕТОКС И ОЧИЩЕНИЕ:</span>
                    <strong> избавление от кислотной нагрузки</strong>
                    <br />
                    Современное питание и высокий уровень стресса
                    <strong>увеличивают закисление организма</strong>, что
                    замедляет обменные процессы, ухудшает самочувствие и
                    повышает риск воспалений. Минералы
                    <strong>нейтрализуют избыточные кислоты</strong>, помогая
                    организму быстрее восстанавливаться.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Чистый организм без токсинов</strong> – лёгкость и
                      улучшение общего самочувствия.
                    </li>
                    <li>
                      <strong>Меньше воспалений и отёков</strong> – ткани
                      работают без перегрузки.
                    </li>
                    <li>
                      <strong>Поддержку работы почек и печени</strong> –
                      активное выведение продуктов обмена.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange"
                      >+ МИНЕРАЛЫ И КИСЛОТНО-ЩЕЛОЧНОЙ БАЛАНС:</span
                    >
                    <br />
                    Минералы <strong>регулируют pH</strong>, помогая организму
                    <strong>своевременно компенсировать отклонения</strong> и
                    поддерживать нормальную работу буферных систем. Это особенно
                    важно для здоровья костей, нервной системы и
                    сердечно-сосудистой системы.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Крепкие кости и суставы</strong> – защита от
                      вымывания кальция.
                    </li>
                    <li>
                      <strong>Стабильность нервной системы</strong> –
                      спокойствие, устойчивость к стрессу.
                    </li>
                    <li>
                      <strong>Здоровье сердца и сосудов</strong> – профилактика
                      гипертонии и перепадов давления.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange"
                      >+ ПРОФИЛАКТИКА ИНСУЛИНОРЕЗИСТЕНТНОСТИ И ИЗБЫТОЧНОГО
                      ВЕСА:</span
                    >
                    <br />
                    Научно доказано:
                    <strong
                      >микроэлементные дефициты связаны с развитием
                      инсулинорезистентности</strong
                    >
                    и нарушением обмена веществ в любом возрасте. Про Баланс
                    восполняет важные минералы,
                    <strong
                      >поддерживая гормональный баланс и регуляцию сахара в
                      крови.</strong
                    >
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Стабильный уровень сахара</strong> – меньше
                      скачков энергии и тяги к сладкому.
                    </li>
                    <li>
                      <strong>Снижение рисков метаболических нарушений.</strong>
                    </li>
                    <li>
                      <strong>Контроль веса и аппетита</strong> – естественное
                      регулирование пищевых привычек.
                    </li>
                  </ul>
                  <h3 class="mt-3 text-center">
                    Натуральный баланс для здоровья
                  </h3>
                  <ul class="mt-3 pl-5">
                    <li>Восстанавливает кислотно-щелочное равновесие</li>
                    <li>Улучшает метаболизм и усвоение питательных веществ</li>
                    <li>Поддерживает энергетический баланс и детокс</li>
                  </ul>
                  <p class="mt-3">
                    <strong>
                      Про Баланс – основа гармонии, здоровья и активного образа
                      жизни!</strong
                    >
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/e1mppkr7sh?web_component=true&seo=true" title="Про Баланс Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-5 mb-5">
                    Микронутриенты в составе
                  </h2>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>K</span>
                      Калий (300 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="" size="x-large">mdi-circle-small</v-icon>
                        Регуляция водного, кислотного и электролитного баланса
                      </li>
                      <li>
                        <v-icon icon="" size="x-large">mdi-circle-small</v-icon>
                        Участвует в регуляции уровня сахара в крови
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Помогает поддерживать щелочную среду в организме
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Поддерживает функции сердечной мышцы и проводящей системы сердца
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Mg</span>
                      Магний (200 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Энергетический метаболизм
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Синтез белков
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        <p>
                          Магний является неотъемлемой составной частью активных
                          центров ферментов.
                        </p>
                        <p class="mt-3">
                          Гликолиз — один из наиболее фундаментальных
                          биохимических путей углеводного метаболизма.
                        </p>
                        <p class="mt-3">
                          Дефицит магния неизбежно приведет к значительному
                          снижению активности по крайней мере шести из 10
                          гликолитических ферментов, способствуя развитию
                          глюкозотолерантности тканей.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Ca</span>
                      Кальций (695 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в процессах образования энергии
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Входит в состав многих ферментов, нормализующих обмен
                        веществ
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Na</span>
                      Натрий (550 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Основной внеклеточный ион, принимающий участие в
                        переносе воды, глюкозы крови
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Cu</span>
                      Медь (453 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Стимулирует усвоение белков и углеводов
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Cr</span>
                      Хром (60 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в регуляции уровня глюкозы крови, усиливая
                        действие инсулина
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        <p>
                          Участвует в регуляции углеводного и липидного обменов,
                          поддерживая нормальный уровень толерантности организма
                          к глюкозе.
                        </p>
                        <p class="mt-3">
                          Биологически активная форма хрома образует комплексное
                          соединение с инсулином, более активное, чем свободный
                          инсулин.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Mo</span>
                      Молибден (80 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Способствует метаболизму углеводов и жиров
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Супер Омега – больше, чем просто Омега-3
                  </h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/super-omega.png"
                        alt=""
                        class="img-super-omega"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p class="mb-5">
                        <strong>Супер Омега</strong> – это не просто стандартная
                        Омега-3, а
                        <strong>расширенный комплекс</strong> полиненасыщенных
                        жирных кислот (ПНЖК) и активных компонентов, которые
                        <strong>усиливают действие друг друга.</strong> Она
                        поддерживает
                        <strong>мозг, сердце, кожу и мышцы</strong>, а также
                        <strong
                          >улучшает усвоение жиров, детоксикацию и
                          антиоксидантную защиту</strong
                        >
                        организма.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <span class="title-orange">+ МОЗГ И КОНЦЕНТРАЦИЯ:</span>
                    <strong> ясность мышления и защита нейронов</strong>
                    <br />
                    <strong>EPA и DHA – это строительные блоки мозга,</strong>
                    обеспечивающие передачу нервных импульсов и защиту нервных
                    клеток от разрушения. Недостаток этих кислот связан с
                    ухудшением памяти, концентрации и эмоциональной
                    устойчивости.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Быстрое мышление и хорошая память</strong> –
                      улучшается передача сигналов между нейронами.
                    </li>
                    <li>
                      <strong
                        >Эмоциональная стабильность и меньше стресса</strong
                      >
                      – защита нервной системы от перегрузок.
                    </li>
                    <li>
                      <strong>Замедление возрастных изменений мозга</strong> –
                      профилактика когнитивных нарушений.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange">+ СЕРДЦЕ И СОСУДЫ:</span>
                    <strong> здоровье сердечно-сосудистой системы</strong>
                    <br />
                    Супер Омега содержит
                    <strong>EPA и DHA в оптимальных дозировках,</strong> которые
                    <strong
                      >снижают уровень «плохого» холестерина (LDL), улучшают
                      текучесть крови и снижают воспаление сосудов.</strong
                    >
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Защиту от атеросклероза и гипертонии</strong> –
                      сосуды остаются эластичными и чистыми.
                    </li>
                    <li>
                      <strong>Стабильный уровень холестерина</strong>
                      – меньше риска сердечно-сосудистых заболеваний.
                    </li>
                    <li>
                      <strong
                        >Улучшение кровообращения и насыщение клеток
                        кислородом</strong
                      >
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange">+ КРАСОТА И ГИБКОСТЬ:</span>
                    <strong> питание кожи и суставов</strong>
                    <br />
                    Сочетание <strong>Омега-3 и Омега-6</strong> поддерживает
                    <strong
                      >здоровье кожи, суставов и соединительных тканей.</strong
                    >
                    Жирные кислоты участвуют в синтезе коллагена, уменьшают
                    воспаления и помогают коже сохранять эластичность.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong
                        >Упругую, увлажнённую кожу без сухости и
                        раздражений</strong
                      >
                    </li>
                    <li>
                      <strong>Гибкие и здоровые суставы</strong>
                      – поддержка хрящей и связок.
                    </li>
                    <li>
                      <strong>Меньше воспалений и акне</strong> – восстановление
                      защитного барьера кожи.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <span class="title-orange"
                      >+ УЛУЧШЕННОЕ УСВОЕНИЕ И ДЕТОКС:</span
                    >
                    <br />
                    В отличие от обычных Омега-3 комплексов,
                    <strong>Супер Омега дополнена бета-глюканами</strong>,
                    которые
                    <strong
                      >улучшают усвоение жиров в ЖКТ и помогают организму
                      быстрее избавляться от токсинов.</strong
                    >
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong
                        >Лучшую усвояемость жиров и жирорастворимых
                        витаминов</strong
                      >
                      (A, D, E, K).
                    </li>
                    <li>
                      <strong
                        >Более чистый организм – ускоренное выведение
                        токсинов</strong
                      >
                    </li>
                    <li>
                      <strong>Поддержку антиоксидантной защиты</strong>
                      – клетки дольше остаются молодыми.
                    </li>
                  </ul>
                  <h3 class="mt-3 text-center">
                    Чем Супер Омега отличается от обычных Омега-3?
                  </h3>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Оптимальные дозировки EPA и DHA</strong> для
                      максимальной пользы.
                    </li>
                    <li>
                      <strong
                        >Дополнительные Омега-6 (γ-линоленовая кислота)</strong
                      >
                      – поддержка кожи и суставов.
                    </li>
                    <li>
                      <strong>Бета-глюканы</strong> – усиление усвоения жиров и
                      антиоксидантная защита.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong
                      >Супер Омега – мощное питание для мозга, сердца, кожи и
                      суставов!</strong
                    >
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/wfoa7vw22c?web_component=true&seo=true" title="Супер Омега Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-7 mb-5">Механизмы действия ПНЖК</h2>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/lipid-normalization.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Нормализация липидного обмена</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/intestine.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Повышенное выделение желчных кислот в кишечнике</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/increase-hdl.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Повышение уровня полезных липопротеидов высокой
                        плотности (ЛПВП)
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/lipid-transort.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Нормализация механизмов транспорта липидов по кровяному
                        руслу
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/gastric.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Улучшение абсорбции жиров в пищеварительном тракте</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/anti-oxidant.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Повышение антиоксидантных функций организма</p>
                    </v-col>
                  </v-row>
                  <div class="mt-7">
                    <h3 class="h3-green">
                      Масло примулы вечерней способствует
                    </h3>
                    <v-row class="row-icon">
                      <v-col cols="2" sm="3" class="col-img-center">
                        <img
                          src="/img/metabolism/primula.png"
                          alt=""
                          class="img-icon-avatar"
                        />
                      </v-col>
                      <v-col cols="10" sm="9" class="col-center">
                        <ul class="ul-check pl-0">
                          <li>
                            <v-icon>mdi-check</v-icon>
                            Снижению содержания холестерина в крови и
                            минимизации риска развития тромбов
                          </li>
                          <li>
                            <v-icon>mdi-check</v-icon>
                            Регулированию уровня сахара
                          </li>
                          <li>
                            <v-icon>mdi-check</v-icon>
                            Регуляции углеводного и липидного обмена
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Эксперты рекомендуют</h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/zvereva.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Светлана Зверева</h3>
                      <p class="text-center">
                        Тренер по продукту LR, провизор, нутрициолог, специалист
                        в области технологии лекарств
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Древнеримский врач и естествоиспытатель Гален говорил: "не
                    бегаешь, пока здоров – побегаешь, когда заболеешь". Мне
                    кажется, это очень точно отражает результат нашего образа
                    жизни. Вредные привычки, плохое питание и бесконечные
                    стрессы ускоряют старение и дряхление тела. И наоборот,
                    полезные привычки, сбалансированное питание и
                    высококачественные БАД – помогают нам сохранить молодость,
                    красоту, энергию в любом возрасте. Невозможно отделить
                    какую-либо систему или орган для восстановления в отрыве от
                    других систем и тела в целом, поэтому нужен так называемый
                    холистический, то есть комплексный подход. Именно такой
                    подход реализуется в наборе Антиэйдж на 3 месяца.»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/bogachuk.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Мария Богачук</h3>
                      <p class="text-center">
                        Кандидат фармацевтических наук, научный сотрудник
                        научно- исследовательской лаборатории, автор научных
                        статей, участник разработок Методических рекомендаций и
                        ГОСТ
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Начиная с 20–25 лет в организме человека запускаются
                    необратимые процессы старения: уменьшается выработка
                    эндогенного коллагена, снижается активность собственных
                    ферментов и синтез АТФ, антиоксидантная система защиты
                    клеток проседает. Замедляется метаболизм белков, углеводов,
                    жиров. Именно в этот период организму необходима постоянная
                    комплексная поддержка в виде недостающих нам витаминов,
                    антиоксидантов и других биологически активных веществ. Набор
                    Антиэйдж сочетает в себе весь недостающий комплекс макро- и
                    микронутриентов и рассчитан на длительный прием. И не нужно
                    думать и подбирать БАДы для поддержки своего организма – за
                    Вас это уже сделали!»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/sevostyanova.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Наталья Севостьянова</h3>
                      <p class="text-center">
                        Доктор биологических наук, докторская диссертация по
                        геронтологии. 16 лет преподавала биохимию в Новгородском
                        Государственном Университете. Основатель проекта
                        НовБиотех
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Восстановление здоровья и сохранение молодости это
                    комплексная поддержка организма. Только комплексно
                    воздействуя на все ключевые органы и ткани можно добиться
                    высокого результата. Набор Антиэйдж позволяет восстановить
                    работу кишечника, белковый обмен, нервную систему, а также
                    активирует обменные процессы. Для перенастройки организма и
                    активации обменных процессов требуется период не менее 3
                    месяцев при соблюдении режима питания, активности и отдыха.»
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Отзывы</h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/meloyan.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Жанна Мелоян</h3>
                      <p class="text-center">Предприниматель</p>
                      <h3 class="text-center mt-3">
                        «С LR я молодею с каждым годом»
                      </h3>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Принимать продукты антиэйдж сейчас для меня образ жизни. А
                    10 лет назад это было вынужденное решение – из-за здоровья.
                    На одном из семинаров я услышала фразу доктора Делбе: «если
                    7 лет принимать алое беспрерывно, то вы омолодите свой
                    организм на 20 лет». Как это омолодить? Разве это возможно?
                    – Думала я в эту секунду, но на всякий случай записала
                    фразу. И вот прошли мои 10 лет приема Алое и других
                    продуктов набора Антиэйдж. Я дала себе время проверить
                    правдивость. Результат, как говорится, на лицо!
                  </p>
                  <p class="font-italic mt-3">
                    Бодрость, продуктивность, энергия, упругая кожа и каблуки –
                    все это добавилось как-то незаметно.
                  </p>
                  <p class="font-italic mt-3">
                    Это моя история, но у вас есть возможность написать свою.
                    Просто попробуйте!»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/shelygin.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Сергей Шелыгин</h3>
                      <p class="text-center">
                        45 лет, мастер спорта, 20 лет занимаюсь бизнесом, 11 лет
                        с LR. С нашими продуктами готовился к своему первому
                        марафону и пробежал его за 3 часа.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «На своем опыте убедился, что физическая форма и уровень
                    здоровья организма в целом сильно зависят от питания. Уже
                    давно обращаю внимание на то, что ем. От этого зависит, чем
                    питаются мои клетки. Магазинная еда не может дать в полном
                    объёме всех необходимых минералов, микроэлементов,
                    витаминов, белков, жиров и т.д., поэтому использование БАДов
                    очень логично.
                  </p>
                  <p class="font-italic mt-3">
                    Наши продукты, в частности готовые комплекные решения в виде
                    наборов Анти эйдж, позволяют полностью закрыть вопрос по
                    питанию клеток и как можно дольше сохранить наше тело. Ведь
                    ему еще нас таскать и, надеюсь, достаточно долго!»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/filippovi.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Алексей и Наталья Филипповы</h3>
                      <p class="text-center">Родители особенного ребенка</p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «История силы: как LR изменил нашу жизнь Те, кто нас знает,
                    в курсе — у нас непростая история, связанная с нашей
                    дочерью.
                  </p>
                  <p class="font-italic mt-3">
                    Когда Лизе был всего месяц, мы попали в больницу с
                    остановкой сердца. Операция, гипоксия, страшный диагноз ДЦП.
                    Нам прямо говорили: "Родители, лучше откажитесь. Вам всю
                    жизнь придётся мучиться. Она не сможет сама есть, всю жизнь
                    кормить через зонд, не будет двигаться, не сможет учиться…".
                    Но мы выбрали другой путь.
                  </p>
                  <p class="font-italic mt-3">Что сейчас?</p>
                  <p class="font-italic mt-3">
                    Сегодня Лизе 14 лет, она учится в пятом классе, развивается,
                    у нас получилось сохранить интеллект, играет в планшете,
                    рубится в игры, живёт полной жизнью. Да, пока передвигается
                    только с нашей поддержкой. Пока мы все таки ее руки и ноги,
                    но мы видим прогресс. Главное — мы забыли, что такое
                    бесконечные походы к врачам! Никаких неврологических
                    препаратов. ЖКТ и иммунитет восстановлены Наш участковый нас
                    потерял, потому что мы просто не болеем! Продукты LR стали
                    частью нашей жизни. Лиза их обожает! Коктейли, Пробаланс,
                    Омега-3, Алоэ Вера гель, Майндмастер — её любимый продукт!
                    Она раскусывает капсулы Омега-3 и ест с удовольствием!
                  </p>
                  <p class="font-italic mt-3">
                    Я не знаю, куда нас выведет этот путь дальше. Но я точно
                    вижу, как работают продукты LR и как сильно они изменили
                    нашу жизнь и жизнь нашей Лизы. Это не просто баночки, это
                    шанс на полноценную жизнь!»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/popova.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Ольга Попова</h3>
                      <p class="text-center">
                        Пенсионер, в прошлом Мед представитель
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Меня зовут Ольга Попова, я пенсионер, в прошлом медицинский
                    представитель в области ортопедии. С 2012 года пользуюсь
                    продукцией LR, и могу с уверенностью сказать – это лучшая
                    инвестиция в здоровье и молодость!
                  </p>
                  <p class="font-italic mt-3">
                    Мне 59 лет, но когда люди узнают мой возраст, они искренне
                    удивляются: «Как вам удаётся так хорошо выглядеть?» А секрет
                    прост – никаких пластических операций и уколов «красоты»,
                    только правильная поддержка организма изнутри.
                  </p>
                  <p class="font-italic mt-3">
                    У многих в моём возрасте полные аптечки лекарств, а у меня
                    только набор «АнтиЭйдж» и активный образ жизни.
                  </p>
                  <p class="font-italic mt-3">
                    Я веду насыщенную жизнь: перелёты, встречи, путешествия,
                    походы в горы – Мезмай, Архыз, Домбай. Люблю купаться в
                    горных озёрах при +4°C – организм легко адаптируется к
                    нагрузкам, иммунитет работает без сбоев. Перелёты, холод,
                    смена климата – всё это не выбивает меня из колеи.
                  </p>
                  <p class="font-italic mt-3">
                    Я чувствую себя бодрой, энергичной, с ясной головой и
                    крепкими суставами. Набор «АнтиЭйдж» – это не просто БАДы, а
                    ключ к активной, здоровой и молодой жизни. Проверено на
                    себе!»
                  </p>
                </div>
              </div>
              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">Свяжитесь со мной, чтобы:</h3>
                <ul class="ul-check-set">
                  <li>получить максимальную скидку и выгодные условия</li>
                  <li>подобрать индивидуальный набор для вас</li>
                  <li>получить подробную консультацию по набору</li>
                </ul>
                <!-- <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="#94b894"
                  class="mt-5"
                ></v-icon> -->

                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AntiAge",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Набор Антиэйдж от LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Комплексное решение для нормализации работы ЖКТ, общеукрепляющее и антистресс действие, поддержка общего хорошего самочувствия, нервной системы, органов детоксикации' },
            { vmid: 'og:title', property: 'og:title', content: 'Набор Антиэйдж от LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Комплексное решение для нормализации работы ЖКТ, общеукрепляющее и антистресс действие, поддержка общего хорошего самочувствия, нервной системы, органов детоксикации' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'AntiAge', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #94b894;
}
.box-border-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 2px;
  border-radius: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.img-top-box {
  margin: 20px 0;
}
.germany-top {
  position: absolute;
  width: calc(40px + (70 - 40) * ((100vw - 320px) / (800 - 320)));
  left: 10%;
}
.anti-age-start {
  max-width: 500px;
  width: 100%;
}
.p-main {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 15px;
}
.mind-master-pic {
  width: 100%;
}
.h2-title-bg {
  text-align: center;
  font-family: raleway-b;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  border-radius: 4px;
}
.gel-avatar,
.girl-mind,
.pro-balance-avatar,
.omega-avatar {
  text-align: center;
  margin-top: 20px;
}
.gel-avatar img {
  width: 80%;
}
.girl-mind img {
  width: 80%;
}
.pro-balance-avatar img {
  width: 73%;
}
.omega-avatar img {
  width: 80%;
}
.synergy-box {
  position: relative;
}
.synergy-box-mobile {
  display: none;
}
.synergy-box img {
  position: absolute;
  width: 80px;
  left: 43%;
}
.h3-col-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}
.p-col {
  text-align: center;
  margin-bottom: 20px;
}
.aloe-leaf-iasc {
  width: 100%;
}
.ul-check-set {
  list-style-type: none;
}
.ul-check-set li {
  position: relative;
  margin-bottom: 5px;
}
.avatar-expert-img {
  border-radius: 50%;
  width: 100%;
  max-width: 120px;
}
.h3-green {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.title-orange {
  text-align: center;
  font-family: raleway-b;
  color: #eab355;
  text-transform: uppercase;
}
.h4-title {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.av-honey,
.av-peach {
  width: 40px;
}
.h4-honey {
  color: #ffbc02;
}
.h4-peach {
  color: #ff8902;
}
.h4-green {
  color: #94b894;
}
.av-leaf-avatar,
.honey-avatar {
  width: 80px;
  height: 80px;
  padding: 3px;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.reishi-img,
.pro12-img,
.img-pro-balance,
.img-super-omega {
  width: 60%;
}
.img-icon-avatar {
  width: 40%;
  border-radius: 50%;
  border: 3px solid #94b894;
  padding: 3px;
}
.col-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-center {
  align-self: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.img-avatar {
  width: 80%;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.ul-check {
  list-style-type: none;
  margin-top: 10px;
}
.ul-check li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 30px;
}
.ul-check li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
}
.h4-icon {
  text-align: center;
  margin-bottom: 10px;
  color: #9dc9b9;
}
.h4-icon span {
  background: #9dc9b9;
  color: #fff;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.micro-div {
  margin-bottom: 25px;
}
.ul-dot {
  list-style-type: none;
}
.ul-dot li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 7px;
}
.ul-dot li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
  top: -4px;
}
.h3-contacts {
  text-transform: uppercase;
  color: #94b894;
  margin-bottom: 10px;
}
@media (min-width: 800px) {
  .h2-green {
    font-size: 36px;
  }
  .germany-top {
    width: 70px;
  }
}
@media (max-width: 680px) {
  .reishi-img,
  .pro12-img,
  .img-pro-balance,
  .img-super-omega {
    width: 80%;
  }
  .img-icon-avatar {
    width: 60%;
  }
  .img-avatar {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .synergy-box {
    display: none;
  }
  .synergy-box-mobile {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .synergy-box-mobile img {
    width: 80px;
  }
  .mind-master-pic {
    width: 150px;
  }
  .aloe-leaf-iasc {
    width: 150px;
  }
  .av-leaf-avatar,
  .honey-avatar {
    width: 60px;
    height: 60px;
  }
  .reishi-img,
  .pro12-img,
  .img-pro-balance,
  .img-super-omega {
    width: 25%;
  }
  .row-icon {
    margin-bottom: 10px;
  }
  .col-img-center {
    padding: 0;
  }
  .col-center {
    font-size: 16px;
  }
  .img-icon-avatar {
    width: 60px;
    height: 60px;
  }
  .img-avatar {
    width: 40%;
  }
}
@media (max-width: 480px) {
  .av-leaf-avatar,
  .honey-avatar {
    width: 50px;
    height: 50px;
  }
  .img-icon-avatar {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 430px) {
  .img-icon-avatar {
    width: 45px;
    height: 45px;
  }
  .col-center {
    font-size: 14px;
  }
  .row-icon {
    margin-left: -7px;
  }
}
@media (max-width: 360px) {
  .av-leaf-avatar,
  .honey-avatar {
    width: 40px;
    height: 40px;
  }
}
</style>